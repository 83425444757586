"use client";

import { useModalContact } from "@/contexts/ModalFastContact";
import ArrowSvg from "@/svg/arrow.svg";
import { usePathname, useRouter } from "next/navigation";
import { toast } from "react-toastify";

export default function DefaultButton({
    text = "Перезвоните мне",
    fill = false,
    arrow = true,
    arrowRight = false,
    className = "",
    cb = { type: "openModal", args: {} },
    onClick = false,
    disable = false,
}) {
    const navigate = useRouter();
    const { openModal, closeModal } = useModalContact();
    const pathname = usePathname();
    return (
        <button
            className={`${className} ${fill ? "btn-fill" : ""} ${
                !arrowRight ? "" : "btn-reverse-items"
            } def-btn `}
            onClick={onClick ? serverFunction : goFunc}
        >
            {text}
            {arrow && (
                <span className="icon">
                    <ArrowSvg />
                </span>
            )}
        </button>
    );

    async function serverFunction() {
        await onClick;
    }
    async function goFunc() {
        const { type, args } = cb;

        switch (type) {
            case "scroll":
                const { elem } = args;
                var scrollDiv = document.querySelector(elem).offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: "smooth" });
                break;
            case "location":
                const { to = "/" } = args;
                navigate.push(to);
                break;
            case "openModal":
                const from =
                    typeof args === "object" ? `url: "${pathname}"` : args;

                openModal(from);
                break;
            case "link":
                const link = args;
                window.open(link, "_blank").focus();
                break;
            case "noty":
                const text = args;
                const modal = cb?.modal;
                if (!text || disable) return toast.warning("Заполните форму");

                const status = await notiAdmin(text);

                if (status) {
                    if (window?.ym) ym(95628501, "reachGoal", "form");

                    if (modal) {
                        closeModal();
                    }
                    if (cb?.cb) {
                        cb.cb();
                    }
                    navigate.push("/thanks");
                } else {
                    toast.error(
                        "Большая нагрузка, свяжитесь с нами самостоятельно"
                    );
                }
                break;
            default:
                return;
        }
    }
    async function notiAdmin(text) {
        const res = await fetch("/api/notify", {
            method: "POST",
            body: JSON.stringify(text),
        });
        const status = await res.json();
        return status;
    }
}
